<template>
 <div class="about_wrap">
     <div class="flex_subb">
          <div class="container">
        <div class="title_banner_sub">
       <div class="title_page">{{get_setting.lang_arr[6].translate}}</div>
        <ul class="uk-breadcrumb">
    <li class="firstbread"><router-link to="/">{{get_setting.lang_arr[0].translate}}</router-link></li>
    <li><a href="#">{{get_setting.lang_arr[6].translate}}</a></li>
</ul>
    </div>
    </div>
     </div>
     <div class="page_start_ab">
       <section class="contact" id="contact">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="maps_google">
                        <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3383.352957821629!2d35.8728778!3d32.005551499999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151c9f7f0e7a1e27%3A0x13b234e162bb1efa!2z2LQuINin2YTZhdmE2YPYqSDYsdin2YbZitin2Iwg2LnZhdmR2KfZhg!5e0!3m2!1sar!2sjo!4v1662942331274!5m2!1sar!2sjo" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> -->
                        <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3386.794354699747!2d35.89539981516043!3d31.912184381241797!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzHCsDU0JzQzLjkiTiAzNcKwNTMnNTEuMyJF!5e0!3m2!1sen!2sjo!4v1678936215979!5m2!1sen!2sjo"  style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                      <!-- <GmapMap ref="mapss"
                        :center='center'
                        :zoom='17'
                        style='width:100%;  height: 400px;'
                        >
                        <GmapMarker
                            :position="center"
                            :draggable="false" 
                        />
                        </GmapMap>  -->
                    
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="title">
                        <h3>{{get_setting.lang_arr[18].translate}}</h3>
                        <p>{{get_setting.lang_arr[19].translate}} </p>
                    </div>
                    <div class="content">
                        <!-- Info-1 -->
                        <a v-if="get_setting.phone" :href="`tel:${get_setting.phone}`" class="info">
                            <ion-icon name="call-outline"></ion-icon>
                            <h4 class="d-inline-block">{{get_setting.lang_arr[14].translate}}
                                <br>
                                <span>{{get_setting.phone}}</span></h4>
                        </a>
                        <!-- Info-2 -->
                        <a v-if="get_setting.email"  :href="`mailto:${get_setting.email}`" class="info">
                           <ion-icon name="mail-unread-outline"></ion-icon>
                            <h4 class="d-inline-block">{{get_setting.lang_arr[15].translate}}
                                <br>
                                <span>{{get_setting.email}}</span></h4>
                        </a>
                        <!-- Info-3 -->
                        <div class="info"  v-if="get_setting.address">
                           <ion-icon name="location-outline"></ion-icon>
                            <h4 class="d-inline-block">{{get_setting.lang_arr[13].translate}}<br>
                                <span>{{get_setting.address}}</span></h4>
                        </div>
                    </div>
                </div>

                <div class="col-md-7">
                    <form @submit.prevent="send_contact_form()">
                        <div class="row">
                            <div class="col-sm-6 mb-5">
                                
                                <b-field :label="(get_setting.lang_arr[20].translate=='')?'Name':get_setting.lang_arr[20].translate" :label-position="labelPosition">
                                    <b-input required v-model="contact.name" :placeholder="(get_setting.lang_arr[20].translate=='')?'Name':get_setting.lang_arr[20].translate"></b-input>
                                </b-field>
                            </div>
                            <div class="col-sm-6 mb-5">
                                <b-field :label="(get_setting.lang_arr[21].translate=='')?'Email':get_setting.lang_arr[21].translate" :label-position="labelPosition">
                                    <b-input required v-model="contact.email" type="email" placeholder="info@platin-jo.com"></b-input>
                                </b-field>
                            </div>
                            <div class="col-sm-12 mb-5">
                               <b-field :label="(get_setting.lang_arr[22].translate=='')?'Title':get_setting.lang_arr[22].translate" :label-position="labelPosition">
                                    <b-input required v-model="contact.subject" :placeholder="(get_setting.lang_arr[22].translate=='')?'Title':get_setting.lang_arr[22].translate"></b-input>
                                </b-field>
                            </div>
                        </div>
                        <div class="form-group mb-0">
                          <b-field :label="(get_setting.lang_arr[23].translate=='')?'Message':get_setting.lang_arr[23].translate"
                                :label-position="labelPosition">
                                <b-input required v-model="contact.message" maxlength="200" type="textarea"></b-input>
                            </b-field>
                        </div>
                       <b-button  :loading="isLoading" native-type="submit" type="is-success">
                       {{(get_setting.lang_arr[24].translate=='')?'Send Now':get_setting.lang_arr[24].translate}}
                    </b-button>
                    </form>
                </div>
            </div>
        </div>
    </section>
     </div>
   
 </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import $ from 'jquery';
export default {
  name: "ContactApp",
  data(){
      return{
          labelPosition: 'on-border',
          isLoading:false,
          contact:{
            name:'',
            email:'',
            subject:'',
            message:''
          },
          errorMsg:'',
           center: { lat:0, lng: 0 },
      }
      },
          computed:{
      ...mapGetters({
        get_setting:'get_setting',
        
      })
    },
    methods:{
    ...mapActions({
        contact_form_api:'contact_form_api'
    }),
        send_contact_form(){
            this.isLoading = true;
            this.contact_form_api(this.contact).then((res=>{
                console.log(res , 'res from app');
                 this.errorMsg='Send <b>Successflly.</b>';
                this.success();
                 this.contact.name='';
                   this.contact.email='';
                     this.contact.subject='';
                       this.contact.message='';
                this.isLoading = false;
            }));
            
        },
         split_lang_lng(){
          let latlng = this.get_setting.latlng;
         let arr_latlng = latlng.split(',');
           this.center.lat = arr_latlng[0]*1;
           this.center.lng = arr_latlng[1]*1;
           console.log(latlng ,arr_latlng[0]*1 , arr_latlng[1]*1);
        },
         success() {
                const notif = this.$buefy.notification.open({
                    duration: 4000,
                    message: this.errorMsg,
                    position: 'is-bottom-right',
                    type: 'is-success',
                    hasIcon: true
                })
                notif.$on('close', () => {
                    // this.$buefy.notification.open('Custom notification closed!')
                }
                )
            },
                danger() {
                const notif = this.$buefy.notification.open({
                    duration: 4000,
                    message: this.errorMsg,
                    position: 'is-bottom-right',
                    type: 'is-danger',
                    hasIcon: true
                })
                notif.$on('close', () => {
                    // this.$buefy.notification.open('Custom notification closed!')
                }
                )
            },
    },
    mounted(){
         this.uk.offcanvas($('#offcanvas-sidebar')).hide();
        this.split_lang_lng();
    }
};
</script>
<style lang="scss"  scoped>
.about_wrap{
    padding-top: 150px;
    min-height: calc(100vh - 150px);
}
.flex_subb{
  background-color: #f4f4f4;
    padding: 30px 10px;
}
.title_banner_sub{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title_page{
        font-size: 30px;
        font-weight: 400;
    }
    .firstbread{
        a{
            color: var(--active_color);
        }
    }
}
// 
.page_start_ab{
    padding-top: 0px;
    .title_strat{
        h1{
        font-size: 22px;
        font-weight: 400;
        }
    }
    blockquote{
       p{
            font-size: 15px;
            padding-top: 8px;
            opacity: 0.6;
       }
    }
    .wrap_video{
        margin-top: 50px;
        .video_about{
        text-align: center;
        width: 100%;
        height: 600px;
        margin: 0 auto;
    }
    }
   .our_vm1 , .our_vm2, .our_vm3{
        margin-top: 30px;
        font-weight: 400;
        h1{
            font-size: 30px;
            padding-bottom: 10px;
            strong{
                color: var(--active_color);
            }
        }
    } 
}
// contact
.maps_google{
    iframe{
        width: 100%;
        height: 450px;
    }
    margin-bottom: 50px;
}
.contact {
    padding: 60px 0;
}

.contact .heading h2 {
    font-size: 30px;
    font-weight: 700;
    margin: 0;
    padding: 0;

}

.contact .heading h2 span {
    color: #ff9100;
}

.contact .heading p {
    font-size: 15px;
    font-weight: 400;
    line-height: 1.7;
    color: #999999;
    margin: 20px 0 60px;
    padding: 0;
}

.contact .form-control {
    padding: 25px;
    font-size: 13px;
    margin-bottom: 10px;
    background: #f9f9f9;
    border: 0;
    border-radius: 10px;
}

.contact button.btn {
    padding: 10px;
    border-radius: 10px;
    font-size: 15px;
    background: #ff9100;
    color: #ffffff;
}

.contact .title h3 {
    font-size: 18px;
    font-weight: 600;
}

.contact .title p {
    font-size: 14px;
    font-weight: 400;
    color: #999;
    line-height: 1.6;
    margin: 0 0 40px;
}

.contact .content .info {
    margin-top: 30px;
    display: block;
}
.contact .content .info:last-child{
margin-bottom: 50px;
}
.contact .content .info ion-icon {
    font-size: 34px;
    padding: 0;
    margin: 0;
    color: var(--active_color);
    margin-right: 20px;
    text-align: center;
}
.contact .content .info h4 {
    font-size: 13px;
    line-height: 1.4;
}

.contact .content .info h4 span {
    font-size: 13px;
    font-weight: 300;
    color: #999999;
}
</style>
<template>
<div class="home_page_wrap">
  <div class="full_page_slider_home" v-if="get_home_setting.active_slider">
    <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slideshow="autoplay: true;animation: scale;pause-on-hover:false">

    <ul class="uk-slideshow-items">
        <li v-for="slide in get_slider_home" :key="slide.id">
            <img :src="slide.image" :alt="slide.title" uk-cover>
            <div class="text_slider">
          <h1>{{slide.title}}</h1>
          <p>{{slide.sub_title}}</p>
        </div>
        </li>
    </ul>
    <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
    <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>

</div>
<div class="flex_abs_home container" v-if="get_home_setting.active_services_slider" >
  <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slider>

<ul class="uk-slider-items uk-child-width-1 uk-child-width-1-2@m uk-child-width-1-3@l uk-grid">
   <li v-for="service_slider in get_service_slider_home" :key="service_slider.id">
     <div class="box_slider1">
    <div class="icons_boxsl">
      <!-- <ion-icon  name="construct-outline"></ion-icon> -->
      <img class="main_icob" :src="service_slider.image" :alt="service_slider.name">
    </div>
    <div class="text_boxsl">
      <span>{{service_slider.name}}</span>
      <p>{{service_slider.desc}}</p>
      <!-- <a href="#">Read More <ion-icon  name="arrow-redo-outline"></ion-icon></a> -->
    </div>
  </div>
   </li>
</ul>

<a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
<a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slider-item="next"></a>

</div>
</div>
  </div>
  <!-- our services -->
  <div class="our_service" v-if="get_home_setting.active_services">
    <div class="container">
      <div class="title_section">
        <h1>{{get_setting.lang_arr[7].translate}}</h1>
      </div>
        <div class="service_box_w">
          <div class="row">
            <div class="col-lg-4" v-for="service in get_service_home" :key="service.id">
              <div class="box_service">
               <div class="icon_service">
                <img :src="service.image" :alt="service.name">
               </div>
               <div class="name_service">
                 <p class="name1">{{service.name}}</p>
                 <p class="name2">{{service.desc}}</p>
               </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
  <!-- our projects -->
  <div class="our_project" v-if="get_home_setting.active_projects" :style="`background-image:url(${get_home_setting.projects_bg_image})`">
    <div class="container">
      <div class="title_section">
        <h1>{{get_setting.lang_arr[8].translate}}</h1>
      </div>
      
      <div class="our_project_wrap">
        <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slider>
    <ul class="uk-slider-items uk-child-width-1 uk-child-width-1-2@m uk-child-width-1-3@l uk-grid">
        <li class="slide_single" v-for="project in get_projects_home" :key="project.id">
            <router-link :to="`/our-projects/${project.id}`">
            <div class="uk-panel">
                <img :src="project.image" width="400" height="600" :alt="project.name">
                <div class="project_info">
                  <h1>{{project.name}}</h1>
                  <p v-html="project.desc"></p>
                </div>
            </div>
            </router-link>
        </li>
    </ul>

    <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
    <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slider-item="next"></a>

</div>
      </div>
    </div>
  </div>
  <!-- Last News -->
  <div class="last_news" v-if="get_home_setting.active_news">
    <div class="container">
       <div class="title_section">
         <h1>{{get_setting.lang_arr[9].translate}}</h1>
      </div>
      <div class="last_news_wrap">
          <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slider>
    <ul class="uk-slider-items uk-child-width-1 uk-child-width-1-2@m uk-child-width-1-3@l uk-grid">
        <li class="slide_box_news" v-for="blog in get_blogs_home" :key="blog.id">
            <div class="uk-panel">
                <div class="img_box">
                  <img :src="blog.image" width="400" height="600" :alt="blog.title">
                </div>
                <div class="news_body">
                  <h1>{{blog.title}}</h1>
                  <p>{{blog.desc}}</p>
                 <router-link :to="`/blogs/info/${blog.id}`">{{get_setting.lang_arr[30].translate}} <ion-icon name="arrow-redo-outline"></ion-icon></router-link>
                </div>
            </div>
        </li>
    </ul>

    <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
    <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slider-item="next"></a>

</div>
      </div>
    </div>
  </div>
  <!-- counterSection -->
  <div class="counter_sec" v-if="get_home_setting.active_rates" >
    <div class="banner_sm">
      <div class="container flex_sml">
        <div class="text_b">
          <p class="b1"> {{get_setting.lang_arr[10].translate}}</p>
          <p class="b2">{{get_setting.lang_arr[11].translate}}</p>
        </div>
        <div class="cv_comp">
          <a target="_black" :href="get_setting.cv">{{get_setting.lang_arr[34].translate}}</a>
        </div>
      </div>
    </div>
    <div class="number_banner" :style="`background-image:url(${get_home_setting.rates_bg_image})`">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-6" v-for="rate in get_rate" :key="rate.id">
            <div class="box_counter">
              <div class="icons_c">
               <img :src="rate.image" :alt="rate.name">
              </div>
              <div class="number_c">
                +{{rate.number}}
              </div>
              <div class="text_c">
                {{rate.name}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- our clients -->
  <div class="our_clients" v-if="get_home_setting.active_clients">
    <div class="container">
       <div class="title_section">
        <h1>{{get_setting.lang_arr[12].translate}}</h1>
      </div>
      <div class="our_clients_wrap">
        <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slider="autoplay: true;autoplay-interval: 5000;pause-on-hover: false;">

    <ul class="uk-slider-items uk-child-width-1-2 uk-child-width-1-4@m uk-child-width-1-6@m uk-grid">
        <li class="clientbox" v-for="client in get_clients.images" :key="client.id">
            <div class="uk-panel"> 
              <img :src="client.image" alt="">
            </div>
        </li>

         
    </ul>

    <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
    <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slider-item="next"></a>

</div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: "homeApp",
  components: {},

  data(){
      return{}
      },
    computed:{
      ...mapGetters({
        get_slider_home:'get_slider_home',
        get_service_slider_home:'get_service_slider_home',
        get_service_home:'get_service_home',
        get_projects_home:'get_projects_home',
        get_blogs_home:'get_blogs_home',
        get_rate:'get_rate',
        get_clients:'get_clients',
        get_home_setting:'get_home_setting',
         get_setting:'get_setting'
      })
    },
    methods:{
      ...mapActions({
        slider_home_api:'slider_home_api',
        home_setting_api:'home_setting_api'
      })
    },
    mounted(){
      this.home_setting_api();
       this.slider_home_api();
    }
};
</script>
<style lang="scss" scoped>
.full_page_slider_home{
.uk-slideshow-items{
  height: 100vh;
  min-height: 100vh !important;
  li{
    &::after{
      content: '';
      background: linear-gradient(301deg, rgba(0, 0, 0, 0.801), #2650a0c7);
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0.6;
    }
  }
}
.flex_abs_home{
      position: absolute;
    bottom: 50px;
    width: 100%;
    color: white;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    // justify-content: space-evenly;
    // display: flex;
    // align-items: center;
}
.box_slider1{
     background-color: #0000008a;
    padding: 30px 14px;
    display: flex;
    flex: 0 0 calc(100% / 3.5);
    align-items: center;
    justify-content: center;
    transition: 0.2s ease-in-out;
    &:hover{
      background-color: rgba(0, 0, 0, 0.716);
      transform: scale(1.05);
    }
    .main_icob{
      width: 80px;
      height: 80px;
      object-fit: contain;
    }
    .text_boxsl{
    display: flex;
    flex-direction: column;
    flex: 0 0 calc( 100% - 80px);
    padding-left: 14px;
    span{
      font-size: 1.2vw;
      font-weight: 500;
    }
    p{
      font-size: 1vw;
      color: var(--active_color2);
      padding-top: 6px;
    }
    a{
      color: var(--active_color2);
      font-size: 1vw;
      padding-top: 6px;
     img{
        vertical-align: middle;
      }
    }
    }
}
.full_page_slider_home .text_slider{
    left: unset;
    right: 4%;
    h1{
        font-size: 50px;
    }
    p{
        font-size: 20px;
    }
}
.text_slider{
  position: absolute;
    top: 35%;
    display: inline-block;
    left: 6%;
    z-index: 2;
    width: 60%;
    h1{
      font-size: 40px;
      font-weight: 500;
      color: var(--active_color2);
    text-shadow: 1px 0px 1px #00000099;
    margin-bottom: 20px;
    text-transform: uppercase;
    }
    p{
      font-size: 20px;
      color: white;
      font-weight: 400;
       text-shadow: 1px 0px 1px #00000099;
    }
}
}
// our serviec
.title_section{
   text-align: center;
   h1{
     color: black;
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    display: inline-block;
    position: relative;
     &::after{
    content: "";
    width: 50%;
    height: 2px;
    background-color: var(--active_color);
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: -10px;
   }
 }

 }
.our_service{
 padding-top:45px;
 padding-bottom: 45px;
 min-height: 550px;
 .service_box_w{
   padding-top: 60px;
 .box_service{
   display: flex;
    align-items: center;
    min-height: 110px;
    margin-bottom: 25px;
    // border: 1px solid gainsboro;
    background-color: white;
    box-shadow: 0px 1px 1px 2px #e8e8e85d;
    padding: 14px;
    transition: 0.2s ease-in-out;
    border-radius: 4px;
    &:hover{
      box-shadow: 0px 1px 1px 2px #4444441c;
      .icon_service{
     ion-icon{
     background-color: var(--active_color2);
     color: black;
     }
     }
    }
   .icon_service{
    img{
      width: 65px;
      height: 65px;
      object-fit: contain;
    }
     ion-icon{
       font-size: 40px;
       margin-right: 2px;
        background-color: var(--active_color);
        color: white;
        padding: 10px;
        border-radius: 100%;
        transition: 0.3s ease-in-out;
     }
   }
   .name_service{
      padding: 0px 14px;
      flex: 0 0 calc(100% - 65px);
     .name1{
       font-size: 15px;
       font-weight: 500;
       color: black;
     }
     .name2{
       font-size: 13px;
       font-weight: 400;
       color: black;
       opacity: 0.4;
     }
   }
 }
 }
}
// our projects
.our_project{
  background-image: url('~@/assets/projectbg.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  padding: 60px 10px 80px 10px;
  position: relative;
  &::after{
    content: '';
    background-color: black;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
  }
  .title_section{
    position: relative;
    z-index: 2;
    h1{
      color: white;
      &::after{
        background-color: var(--active_color);
      }
    }
  }
  .our_project_wrap{
    position: relative;
    z-index: 2;
    padding-top: 65px;
    .slide_single{
      &:hover{
        .project_info{
        transform: translate3d(0,0,0);
        }
      }
      img{
    width: 100%;
    height: 280px;
    object-fit: cover;
      }
      .project_info{
    background: linear-gradient(to bottom, rgba(72, 76, 97, 0) 0%, rgba(72, 76, 97, 0.767) 75%);
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    padding: 140px 20px;
    transform: translate3d(0,100%,0);
    transition: opacity 0.6s, transform 0.6s;
    h1{
      font-size: 17px;
      font-weight: 500;
      margin-bottom: 10px;
      color: white;
    }
    p{
      font-size: 14px;
      color: white;
    }
      }
    }
  }
}
.last_news{
  padding-top: 50px;
  .last_news_wrap{
    padding-top: 40px;
    .slide_box_news{
      &:hover{
        .uk-panel{
          img{
            transform: scale(1.1);
          }
        }
      }
      .uk-panel{
         border: 1px solid gainsboro;
      }
      .img_box{
        overflow: hidden;
      }
      .news_body{
        padding: 14px 8px;
        color: black;
        min-height: 120px;
        h1{
          color: black;
          font-size: 16px;
          font-weight: 500;
          padding-bottom: 8px;
        }
        p{
          font-size: 14px;
          opacity: 0.5;
          padding-top: 2px;
        }
        a{
          color: var(--active_color2);
          font-size: 14px;
          font-weight: 500;
          margin-top: 8px;
          display: inline-block;
          transition: 0.2s ease-in-out;
          ion-icon{
            vertical-align: middle;
          }
          &:hover{
            color: var(--active_color);
          }
        }
      }
      img{
        height: 240px;
        width: 100%;
        max-width: 100%;
        object-fit: cover;
        transition: 0.2s linear;
      }
    }
  }
}
// counter Section
.counter_sec{
  padding-top: 60px;
  .banner_sm{
    background: linear-gradient(45deg, var(--bs-gray-100), var(--bs-gray-400));
   
    color: white;
   .flex_sml{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    .text_b{
      .b1{
        font-size: 18px;
        font-weight: 500;
        color: var(--active_color);
      }
      .b2{
        font-size: 20px;
        font-weight: 600;
        color: var(--active_color);
      }
    }
    .cv_comp{
      a{
        color: white;
        background-color: var(--active_color);
        padding: 10px 14px;
        border-radius: 30px;
        font-weight: 500;
        font-size: 14px;
        transition: 0.2s ease-in-out;
        &:hover{
          background-color: var(--active_color2);
          color: black;
        }
      }
    }

   }
  }
  .number_banner{
    background-image: url('~@/assets/numberbg.jpeg');
    background-size: cover;
    background-attachment: fixed;
    padding: 80px 10px;
    position: relative;
    &::after{
      content: '';
          background: linear-gradient(179deg, black,var(--active_color));
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.8;
    }
  .box_counter{
    position: relative;
    z-index: 2;
    text-align: center;
    color: white;
    .icons_c{
      img{
        width: 80px;
        height: 80px;
        object-fit: contain;
      }
      ion-icon{
        font-size: 80px;
      }
    }
    .number_c{
      font-size: 35px;
    }
    .text_c{
      font-size: 25px;
    }
  }
  }
}
// our clients
.our_clients{
  padding-top: 40px;
  .our_clients_wrap{
    padding-top: 30px;
    .clientbox{
      img{
        width: 100%;
        height: 120px;
        object-fit: contain;
        object-position: center;
      }
    }
  }
}


@media(max-width:992px){
      .full_page_slider_home .text_slider {
    width: 70%;
}
.counter_sec .number_banner .box_counter {
    margin-bottom: 40px;
}
.counter_sec .number_banner .box_counter .icons_c ion-icon {
    font-size: 60px;
}
.counter_sec .number_banner .box_counter .number_c {
    font-size: 30px;
}
.counter_sec .number_banner .box_counter .text_c {
    font-size: 20px;
}
}
@media(max-width:767px){
      .full_page_slider_home .text_slider {
       width: 100%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    padding: 0px 10px;
    h1{
      font-size: 25px;
    }
    p{
      font-size: 14px;
    }
}
.full_page_slider_home .flex_abs_home {
    position: relative;
    bottom: unset;
    display: flex;
    padding-top: 40px;
    flex-wrap: wrap;
}
.full_page_slider_home .box_slider1{
    flex: 0 0 94%;
    margin: 0 auto;
    margin-bottom: 30px;
    justify-content: flex-start;
    align-items: flex-start;
}
.full_page_slider_home .box_slider1 .text_boxsl span {
    font-size: 16px;
    font-weight: 500;
}
.full_page_slider_home .box_slider1 .text_boxsl a{
    font-size: 14px;
    padding-top: 6px;
}
.counter_sec .banner_sm .flex_sml .text_b .b1{
    font-size: 13px;
    font-weight: 500;
}
.counter_sec .banner_sm .flex_sml .text_b .b2{
    font-size: 15px;
    font-weight: 600;
}
.flex_sml .cv_comp a{
    padding: 8px 10px !important;
    font-size: 12px !important;
}
}
</style>
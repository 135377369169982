<template>
<div class="all_wrap">
    <div style="min-height:100vh" v-if="!get_details_projects">
        laoder app
</div>
 <div class="about_wrap descr" v-if="get_details_projects">
     <div class="flex_subb">
          <div class="container">
        <div class="title_banner_sub">
        <div class="title_page">{{get_details_projects.name}}</div>
        <ul class="uk-breadcrumb">
    <li class="firstbread"><a href="#">{{get_setting.lang_arr[0].translate}}</a></li>
    <li><a href="#">{{get_details_projects.name}}</a></li>
</ul>
    </div>
    </div>
     </div>
     <div class="page_start_ab">
        <!-- {{get_details_projects}} -->
       <div class="container">
      <div class="row">
          <div class="col-xl-7 col-lg-6 col-12 slider_col" v-if="get_details_projects.images">
               
    <agile class="main" ref="main" :options="options1" :as-nav-for="asNavFor1">
    <div class="slide" v-for="(slide, index) in get_details_projects.images" :key="index" :class="`slide--${index}`">
        <div uk-lightbox>
            <a :href="slide.img">
                <img :src="slide.img"/>
            </a>
           
        </div>
       </div>
  </agile>
  <agile class="thumbnails" ref="thumbnails" :options="options2" :as-nav-for="asNavFor2">
    <div class="slide slide--thumbniail" v-for="(slide, index) in get_details_projects.images" :key="index" :class="`slide--${index}`" @click="$refs.thumbnails.goTo(index)"><img :src="slide.img"/></div>
    <template slot="prevButton"><i class="fas fa-chevron-left"></i></template>
    <template slot="nextButton"><ion-icon name="chevron-forward-outline"></ion-icon></template>
  </agile>
          </div>
          <div class="col-xl-5 col-lg-6 col-12">
              <div class="title_proj">
                  <h1>{{get_setting.lang_arr[32].translate}}</h1>
              </div>
              <div class="text_proj">
                  <p v-html="get_details_projects.desc"></p>
              </div>
              <hr style="margin:10px !important">
              <div class="flex_check_list">
                  <div class="single_check" v-for="list in get_details_projects.more_details" :key="list.id">
                      <div class="left_check">
                          <div class="icon_check">
                        <ion-icon name="checkmark-outline"></ion-icon>
                      </div>
                      <div class="title_check">
                          <span>{{list.name}}</span>
                      </div>
                      </div>
                      <div class="data_cehck">
                          <p v-html="list.desc"></p>
                      </div>
                  </div>
              </div>
              <!-- client name project -->
              <div class="client_name" v-if="get_details_projects.client">
                   <div class="title_proj">
                  <h1>{{get_setting.lang_arr[33].translate}}</h1>
              </div>
                  <div class="single_check">
                      <div class="left_check">
                          <div class="icon_check">
                        <ion-icon name="checkmark-outline"></ion-icon>
                      </div>
                      <div class="title_check">
                          <span>{{get_details_projects.client}} </span>
                      </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
       </div>
     </div>
   
 </div>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import { VueAgile } from 'vue-agile'

import $ from 'jquery';
export default {
    name:"ProjectInfo",
    data() {
        return {
            gallery: false,
            asNavFor1: [],
			asNavFor2: [],
			options1: {
				dots: false,
				fade: true,
				navButtons: false,
                autoplaySpeed:4000,
                speed:1000
			},
			
			options2: {
				autoplay: true,
				centerMode: true,
				dots: false,
				navButtons: true,
				slidesToShow: 3,
                autoplaySpeed:4000,
                speed:1000,
				responsive: [
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 5
                    }
                },
                
                {
                    breakpoint: 1000,
                    settings: {
                        navButtons: true
                    }
                }
            ]
				
			},
		}
        
    },
    computed:{
      ...mapGetters({
        get_details_projects:'get_details_projects',
        get_setting:'get_setting'
      })
    },
    methods: {
        ...mapActions({
        details_projects_api:'details_projects_api',
      }),
        switchGallery(value) {
            this.gallery = value
            if (value) {
                return document.documentElement.classList.add('is-clipped')
            } else {
                return document.documentElement.classList.remove('is-clipped')
            }
        }
    },
    mounted(){
         this.uk.offcanvas($('#offcanvas-sidebar')).hide();
      this.details_projects_api(this.$route.params.id).then((res)=>{
        console.log('loader end', res);
        this.asNavFor1.push(this.$refs.thumbnails)
		this.asNavFor2.push(this.$refs.main)
      });

    // console.log(this.$s)
    },
     destroyed(){
          this.$store.commit('set_details_projects', null);
    }
}
</script>
<style lang="scss" scoped>
.descr{
    min-height: 100vh;
}
.about_wrap{
    padding-top: 150px;
    min-height: calc(100vh - 150px);
}
.flex_subb{
  background-color: #f4f4f4;
    padding: 30px 10px;
}
.title_banner_sub{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title_page{
        font-size: 30px;
        font-weight: 400;
    }
    .firstbread{
        a{
            color: var(--active_color);
        }
    }
}
// 
.page_start_ab{
    padding-top: 50px;
    .title_strat{
        h1{
        font-size: 22px;
        font-weight: 400;
        }
    }
    blockquote{
       p{
            font-size: 15px;
            padding-top: 8px;
            opacity: 0.6;
       }
    }
    .wrap_video{
        margin-top: 50px;
        .video_about{
        text-align: center;
        width: 100%;
        height: 600px;
        margin: 0 auto;
    }
    }
   .our_vm1 , .our_vm2, .our_vm3{
        margin-top: 30px;
        font-weight: 400;
        h1{
            font-size: 30px;
            padding-bottom: 10px;
            strong{
                color: var(--active_color);
            }
        }
    } 
}
// slider
.slider_col{
    margin-bottom: 35px;
}
.is-active .al img {
    border: 1px solid #fff;
    filter: grayscale(0%);
}
.al img {
    border: 1px solid transparent;
    filter: grayscale(100%);
}
.title_proj{
    position: relative;
   h1{
        font-size: 22px;
    font-weight: 500;
    margin-bottom: 20px;
    &::after{
        content: '';
        position: absolute;
        left: 0;
        right: auto;
        margin-left: auto;
        margin-right: auto;
        bottom: -10px;
        height: 2px;
        width: 14%;
        background-color: var(--active_color);
    }
   }
}
.text_proj{
    p{
        font-size: 14px;
        font-weight: 400;
        opacity: 0.6;
        margin-bottom: 14px;
    }
}
.single_check{
        display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px dashed rgb(148, 146, 146);
    margin-bottom: 14px;
    padding-bottom: 14px;
}
.left_check{
    display: flex;
    align-items: center;
    margin-right: 12px;
    .title_check{
        font-size: 14px;
        font-weight: 500;
    }
    ion-icon{
        margin-right: 10px;
        font-size: 24px;
        vertical-align: middle;
        color: var(--active_color);
        font-weight: 600;
    }
}
.data_cehck{
    font-size: 14px;
    opacity: 0.6;
}
// client
.client_name{
    margin-top: 25px;
    .title_proj{
        margin-bottom: 35px;
    }
    .single_check{
        width: 100% !important;
        border-bottom: 0px !important;
        .left_check{
            margin-right: 0px !important;
        }
    }
}
</style>
<style>


.main {
  margin-bottom: 30px;
  touch-action: none;
}

.thumbnails {
  margin: 0 -5px;
  width: calc(100% + 10px);
  touch-action: none;
}

.agile__nav-button {
  background: transparent;
  border: none;
  color: #ccc;
  cursor: pointer;
  font-size: 24px;
  transition-duration: 0.3s;
}
.thumbnails .agile__nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.thumbnails .agile__nav-button--prev {
  left: -45px;
}
.thumbnails .agile__nav-button--next {
  right: -45px;
}
.agile__nav-button:hover {
  color: #888;
}
.agile__dot {
  margin: 0 10px;
}
.agile__dot button {
  background-color: #eee;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 10px;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  transition-duration: 0.3s;
  width: 10px;
}
.agile__dot--current button, .agile__dot:hover button {
  background-color: #888;
}

.slide {
  align-items: center;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  height: 450px;
  justify-content: center;
}
.slide--thumbniail {
  cursor: pointer;
  height: 100px;
  padding: 0 5px;
  transition: opacity 0.3s;
}
.slide--thumbniail:hover {
  opacity: 0.75;
}
.slide img {
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  width: 100%;
}</style>